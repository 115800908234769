@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('quill.css');

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html {
	height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background-color: #EEEEEE;
}

a {
	text-decoration: none;
}

#root {
	min-height: 100vh;
}

.MuiPickersCalendar-week>div:nth-child(6),
.MuiPickersCalendar-week>div:nth-child(7) {
	background: lightyellow;
}

.MuiPickersCalendar-week>div:nth-child(6)>.MuiPickersDay-day,
.MuiPickersCalendar-week>div:nth-child(7)>.MuiPickersDay-day {
	color: red;
}

input::placeholder {
	font-size: 14px;
}
